<div class="container" >
  <span class="gap"></span>
  <img src="http://images.ctfassets.net/f6ou4743hwzs/2xaL3IYyS5iU8bcXoWAOdX/9d1784950839614bbbcaa593c23ee090/whyblm.jpg" defer>
  <span class="gap"></span>
  <h1>Why Bayou Lacombe Museum?</h1>
  <hr class="light">
  <span class="gap"></span>
  <p class="lead">
    The geography and history of the Bayou Lacombe region offers the most comprehensive review of these subjects for St. Tammany Parish. From the coastal marshes along Lake Pontchartrain’s shores through the transitions of first cypress swamp and bottom land hardwoods to pine forests, the major bio-geographic communities are represented.
    Lacombe’s earliest history, found in the shell mounds along the banks of Bayou Lacombe, provide evidence of how native people lived in the region for centuries before the arrival of European settlers. One of the first needs of the early colonists building New Orleans was for materials that could resist the multiple forces of decay found in subtropical Louisiana. From the Choctaw of the North Shore, the colonists learned of the clay and timber resources across Lake Pontchartrain.
    Within the first quarter century of establishing New Orleans, the first Europeans arrived on the North Shore along Bayou Lacombe to begin manufacturing bricks and cutting cypress and pine forest to build the new city.
    A few of these French colonists moved across the lake to establish small isolated settlements. One of the first and longest enduring was established at Bayou Lacombe. These early settlers and the native Choctaw formed an amalgamated population. Each with its separate cultural identity, they developed a new shared culture. La Toussaint, All Saints’ Day, is an annual Lacombe celebration that combines elements of French Catholic and Choctaw traditions honoring our ancestors. This tradition continues to be celebrated today.
    Throughout the nineteenth century Lacombe has contributed to the development of St. Tammany Parish. It began as a site for brick making and lumber. Building of vessels to transport these goods became a secondary industry. With the arrival of the railroad, timber became a dominant industry. In the twentieth century, Lacombe evolved with the automobile and electricity from an isolated rural fishing community to an exurb and suburb of greater New Orleans. As we move into the twenty-first century, crabbing is still a local industry, but most of the population travels across the region to work in the multitude of businesses from building rockets to caring for the infirmed.
    The history of Lacombe allows us to tell the important stories of how we are connected to this land and how all of the different peoples from the first to the latest arrivals have contributed to the community. The Bayou Lacombe Museum exists to celebrate and share this rich cultural heritage.
  </p>
</div>
      
                            