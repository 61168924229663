import { Component, OnInit } from '@angular/core';
import { ContentfulService } from '../../../core/contentful.service';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Entry } from 'contentful';
import { switchMap } from 'rxjs/operators';
import { NavbarService } from '../../../core/navbar.service';

@Component({
  selector: 'app-event-details',
  templateUrl: './event-details.component.html',
  styleUrls: ['./event-details.component.css']
})
export class EventDetailsComponent implements OnInit {
  blmEvents: Entry<any>;
  constructor(public ContentfulService: ContentfulService,
    private route: ActivatedRoute,public nav: NavbarService) { }

  ngOnInit() {
    this.nav.show();
    this.route.paramMap
    .pipe(switchMap((params: ParamMap) => this.ContentfulService.getEvent(params.get('slug'))))
    .subscribe( blmEvents=> this.blmEvents = blmEvents);
  }

}
