// ./src/app/contentful.service.ts
import { Injectable } from '@angular/core';
// import Contentful createClient and type for `Entry`
import { createClient, Entry } from 'contentful';
import {marked} from 'marked';
import {environment} from '../../environments/environment';
// configure the service with tokens and content type ids
// SET YOU OWN CONFIG here
const CONFIG = {
  space: environment.contentful.space,
  accessToken: environment.contentful.accessToken,

  contentTypeIds: {
    blmEvents:'blmEvents',
    blmDaily:'blmDaily',
    blmExhibits:'blmExhibits',
    blmMiscellaneous:'blmMiscellaneous',
    hero:'heroSection',
    tempExhibit:'temporaryExhibit'
  }
}

@Injectable()
export class ContentfulService {
  private cdaClient = createClient({
    space: CONFIG.space,
    accessToken: CONFIG.accessToken
  });

  constructor() { }
  // getTempExhibits(query?: object): Promise<Entry<any>[]> {
  //   return this.cdaClient.getEntries(Object.assign({
  //     content_type: CONFIG.contentTypeIds.tempExhibit
  //   }, query))
  //   .then(res => res.items);
  // }
  // getTempExhibit(slug: string): Promise<Entry<any>> {
  //   return this.getTempExhibits({ 'fields.url': slug })
  //   .then(items => items[0])
  // }
  getHero(query?: object): Promise<Entry<any>[]> {
    return this.cdaClient.getEntries(Object.assign({
      content_type: CONFIG.contentTypeIds.hero
    }, query))
    .then(res => res.items);
  }
  getItems(query?: object): Promise<Entry<any>[]> {
    return this.cdaClient.getEntries(Object.assign({
      content_type: CONFIG.contentTypeIds.blmMiscellaneous
    }, query))
    .then(res => res.items);
  }
getEvents(query?: object): Promise<Entry<any>[]> {
  return this.cdaClient.getEntries(Object.assign({
    content_type: CONFIG.contentTypeIds.blmEvents
  }, query))
  .then(res => res.items);
}
getEvent(slug: string): Promise<Entry<any>> {
  return this.getEvents({ 'fields.url': slug })
  .then(items => items[0])
}

  getDaily(query?: object): Promise<Entry<any>[]> {
    return this.cdaClient.getEntries(Object.assign({
      content_type: CONFIG.contentTypeIds.blmDaily
    }, query))
    .then(res => res.items);
  }
  getDailies(slug: string): Promise<Entry<any>> {
    return this.getDaily({ 'fields.url': slug })
    .then(items => items[0])
}

getExhibits(query?: object): Promise<Entry<any>[]> {
  return this.cdaClient.getEntries(Object.assign({
    content_type: CONFIG.contentTypeIds.blmExhibits
  }, query))
  .then(res => res.items);
}
getExhibit(slug: string): Promise<Entry<any>> {
  return this.getExhibits({ 'fields.url': slug })
  .then(items => items[0])
}
 // convert markdown string to 
 markdownToHtml(md: string) {

  return marked(md)
}
}