import { Component, OnInit } from '@angular/core';
import { ContentfulService } from '../../core/contentful.service';
import { Entry } from 'contentful';

@Component({
  selector: 'app-daily',
  templateUrl: './daily.component.html',
  styleUrls: ['./daily.component.css']
})
export class DailyComponent implements OnInit {
  public blmDaily: Entry<any>[] = [];
  constructor(private contentfulService: ContentfulService) { }

  ngOnInit() {
    this.contentfulService.getDaily()
    .then(blmDaily => this.blmDaily = blmDaily);
  }

}
