import { Component, OnInit } from '@angular/core';
import { ContentfulService } from 'src/app/core/contentful.service';
import { Entry } from 'contentful';
import { NavbarService } from '../../core/navbar.service';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.css']
})
export class EventsComponent implements OnInit {
  events: Entry<any>[] = [];
  constructor(private contentfulService: ContentfulService,public nav: NavbarService,private title: Title, private meta: Meta,) { }

  ngOnInit() {
    this.nav.show()
    this.contentfulService.getEvents()
    .then(events => 
      this.events = events
    );
      console.log(this.events)
    this.title.setTitle('Events | Bayou lacombe Museum');
    this.meta.addTags([
      { name: 'og:url', content: ''},
      { name: 'og:title', content: '' },
      { name: 'og:description', content: '' },
      { name: 'og:image', content: ''},
      { name: 'og:locale', content: 'en_US' },
      { name: 'twitter:card', content: 'summary' },
      { name: 'twitter:image', content: '' },
      { name: 'twitter:title', content: '' },
      { name: 'twitter:description', content: '' },
      { name: 'keywords', content: '' },
    ])
    
  }

}
