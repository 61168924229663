<span class="gap"></span>
<a class="d-md-none text-dark back" routerLink=""><mat-icon>arrow_back</mat-icon> Go To Home</a>
<span class="gap"></span>
  <div class="container padding">
    <div class="row text-center">
  <div class="container padding text-center">
    <div class="col-12 text-center">
              
        <h1 class="display-4">Contact Us</h1>
    </div>
    <p class="lead">
        We’re here to help and answer any question you might have. We look forward to hearing from you! 🙂
    </p>
    <div class="form-group "[ngClass]="{
      'has-success': email.valid && (email.dirty || email.touched),
      'has-danger': email.invalid && (email.dirty || email.touched)
    }">
      
        <form class="col-12" [formGroup]="myForm" [hidden]="success" autocomplete="off" (ngSubmit)="onSubmit()">
      <div class="col-12">
        <!--For testing-->
         <!--Value: {{myForm.value | json}}-->
        <!--Name-->
         <mat-form-field appearance="fill">
           <input matInput placeholder="Name" formControlName="name">
            <mat-error *ngIf="name.errors && (name.dirty || name.touched)">
                Name Required
            </mat-error>
          </mat-form-field>
      </div>
       <!--Email-->
      <div class="col-12 email">
          <mat-form-field appearance="fill">
            <input matInput placeholder="Email" formControlName="email">
              <mat-error *ngIf="email.errors && (email.dirty || email.touched)">
                  Invalid Email
              </mat-error>
          </mat-form-field>
        <!--Company-->

          </div>
          <!--Text Area-->
          <div class="col-12 ">
          <mat-form-field appearance="fill">
             <textarea  class="txtarea" matInput placeholder="How Can We Help?" formControlName="message"></textarea>
           </mat-form-field>
       </div>
       <div class="col-12 ">
          
          <button mat-flat-button color="accent" [disabled]="myForm.invalid" type="submit">Send</button>
       </div>
        
        </form>
        <div class="jumbotron text-center" *ngIf="success">
    
          <h2>Thank You, We have received your submission</h2>
           </div>
      </div>
  
  
  </div>

  
  