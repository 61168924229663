import { Injectable } from '@angular/core';
import { BreakpointObserver, Breakpoints,BreakpointState } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
@Injectable()
export class NavbarService {
  visible: boolean;
  back: boolean;
  public colSize = 4;
  public isMobile : boolean = false;
  isHandset$: Observable<BreakpointState> = this.breakpointObserver.observe(Breakpoints.Handset)
  constructor(private breakpointObserver: BreakpointObserver) {  
    
      this.back = false;
      this.visible = false;
  }




  hideBack() { this.back = false; }

  showBack() { this.back = true; }
  
  hide() { this.visible = false; }

  show() { this.visible = true; }

  toggle() { this.visible = !this.visible; }

}
