<div><app-top></app-top> 
  <div *ngFor="let hero of hero">
    <div class="hero-image" [style.background]="'url('+hero.fields.image.fields.file.url+')'">
      <div class="fade-bg">
          <div class="hero-text">
            <p [innerHTML]="hero.fields.exhibitType"></p>
            <h1 class="display-1" [innerHTML]="hero.fields.title"></h1>
            <br>
            <h3 [innerHTML]="hero.fields.subheaderText"></h3>
            <br>
        <div class="dates">
          <span [innerHTML]="hero.fields.exhibitDates"></span>
        </div>
        <span class="gap"></span>
        <!-- <div>
         <button mat-fab [routerLink]="['/exhibits', hero.fields.url]"><mat-icon class="material-icons-round">arrow_forward</mat-icon></button> 
        </div>   -->
         
      </div>
      </div>

    </div>
  </div>

  <!-- Explore  -->
  <span class="gap"></span>
  <div class="hero-image" style="background: url('https://images.ctfassets.net/f6ou4743hwzs/1RqmF6LM1bWmPTXm4EZQNf/84f31f19a227cbf626570c73c5850c7d/BayouLacombeMuseum.jpg')">
     <div class="fade-bg-white">
    <div class="container">
      <span class="gap"></span>
      <h1 class="explore">Explore</h1>
      <span class="gap"></span>
      <div class="explore-cards">
        <mat-card class="explore-card" style="background: url('https://images.ctfassets.net/f6ou4743hwzs/5oJNVQ5JiaRGOwJ9gi41sn/475c51cbb180eecac19ae4af379ecfc3/Homepage.jpeg')">
        <mat-card-header>
          <mat-basic-chip>
            <mat-chip>Museum Tour</mat-chip>
          </mat-basic-chip>
        </mat-card-header>
        <div class="card-content">
          <mat-card-content>
          <h2>Visit Us</h2>
          <p>Friday & Saturday : Noon - 4P.M. </p>
          <p>Adult: $3, Seniors: $2, Students: $1, Toddlers: Free </p>
        </mat-card-content>
        <mat-card-actions>
          <a target="_blank" mat-fab href="https://www.google.com/maps/place/Bayou+Lacombe+Museum/@30.3149554,-89.9441327,17z/data=!4m5!3m4!1s0x0:0xc2a5b6489d867d6f!8m2!3d30.3156913!4d-89.9431989"><mat-icon class="material-icons-round">arrow_forward</mat-icon></a>
        </mat-card-actions>
        </div>
      </mat-card>
      <mat-card *ngIf="blmEvents" class="explore-card" [style.background]=" 'url('+blmEvents.fields.images[0].fields.file.url+')'">
      <mat-card-header>
        <mat-basic-chip>
          <mat-chip color="accent" selected>Upcoming Event</mat-chip>
        </mat-basic-chip>
      </mat-card-header>
      <div class="card-content">
        <mat-card-content>
        <h2 [innerHTML]="blmEvents.fields.title | mdToHtml"></h2>
        <p [innerHTML]="blmEvents.fields.date"></p>
      </mat-card-content>
      <mat-card-actions>
        <button mat-fab [routerLink]="['/events', blmEvents.fields.url]"><mat-icon class="material-icons-round">arrow_forward</mat-icon></button>
      </mat-card-actions>
      </div>
    </mat-card>
    <mat-card class="explore-card" style="background: url('https://images.ctfassets.net/f6ou4743hwzs/2666n0sNDqIQgCObYIwgKd/28324dd15c6be2a9b6f8ad3aef4373cf/Exhibits.svg')">
    <mat-card-header>
      <mat-basic-chip>
        <mat-chip color="accent" selected>Discover</mat-chip>
      </mat-basic-chip>
    </mat-card-header>
    <div class="card-content">
      <mat-card-content>
      <h2>Our Exhibits</h2>
      <p>Explore the rich cultural history of Bayou Lacombe</p>
    </mat-card-content>
    <mat-card-actions>
      <button mat-fab routerLink="/exhibits"><mat-icon class="material-icons-round">arrow_forward</mat-icon></button>
    </mat-card-actions>
    </div>
  </mat-card>
      </div>

    </div>
  </div>
  </div>
 
  <span class="gap"></span>
</div>











<!-- <div *ngFor="let hero of hero" class="img" [style.background]="'url('+hero.fields.image.fields.file.url+')'" alt="Photo of Bayou Lacombe Museum">

</div>
      <br>
        <div class="col-12 text-center" *ngIf="blmDaily">
  <h3>Admission</h3>
  <p class="">Adults: <strong>$3</strong> (ages: 18 - 64) | Seniors: <strong>$2</strong> (ages: 65 & over) | Students: <strong>$1</strong> (ages: 6-17) | Children 5 & under <strong>Free</strong></p>
          
              <h4 [innerHTML]="blmDaily.fields.shortDescription | mdToHtml"></h4>
</div>

      <div class="">
        <div class="row">
          <div class="col-12">
<hr class="light">
<br/>
<div class="container">
  <div class="card-deck">
    <div class="card" *ngIf="blmEvents">
      <a class="link" [routerLink]="['/events', blmEvents.fields.url]">
      <img class="card-img-top" [src]="blmEvents.fields.images[0].fields.file.url" alt="Upcoming Events" defer>
      <div class="card-body">
        <h5 [innerHTML]="blmEvents.fields.title | mdToHtml"></h5>
        <p class="card-text badge badge-dark" [innerHTML]="blmEvents.fields.date"></p>
        <p class="lead" [innerHTML]="blmEvents.fields.shortDescription | mdToHtml"></p>
        <button [routerLink]="['/events', blmEvents.fields.url]" mat-button color="accent" class="card-link">Read More <mat-icon>arrow_forward</mat-icon></button>
      </div>
    </a>
    </div>
    <div class="card">
      <a class="link" routerLink="/exhibits">
        <img class="card-img-top" src="https://images.ctfassets.net/f6ou4743hwzs/2666n0sNDqIQgCObYIwgKd/28324dd15c6be2a9b6f8ad3aef4373cf/Exhibits.svg" alt="Painting Of The Aboriginal People Of Southern America" defer>
        <div class="card-body">
          <h3 class="card-title">Exhibits</h3>
          <p><small class="badge badge-dark">Discover</small></p>
          <button routerLink="/exhibits" mat-button color="accent" class="card-link">Read More <mat-icon>arrow_forward</mat-icon> </button>
        </div>
      </a>
    </div>
  </div>
  <br>
  <div class="volunteer-section">
    <div class="text-center">
      <h3 class="display-4">Volunteer Opportunities</h3>
      <hr class="light">
      <div class="text-left">
        <div class="card-deck">
          <div class="card">
            <img src="https://images.ctfassets.net/f6ou4743hwzs/7LrFnV07pJWmUUh0Qtp8OH/1e21f94248107e5288fe491ecc887921/Daily.jpg" class="card-img-top" alt="Daily Operations" defer>

            <div class="card-body">              
              <small class="card-title">Daily Operations</small>
              <p class="card-text lead">Participate at the Museum (open/close/operate building, greet visitors, interpret displays, and conduct sales). Also conduct general maintenance and cleaning.</p>
            </div>
          </div>
          <div class="card">
            <img src="https://images.ctfassets.net/f6ou4743hwzs/6ULaVgrWQQG5XULqWX49h6/a24d730df42745bf075daf0a9084adc3/Special_Events.jpg" class="card-img-top" alt="Photo Of a Planner" defer>

            <div class="card-body">
              <small class="card-title">Special Events</small>
              <p class="card-text lead">Help out with special events and cleaning. Help with school groups and other organization on structured learning programs.</p>
            </div>
          </div>
          <div class="card">
            <img src="https://images.ctfassets.net/f6ou4743hwzs/647MVbjMlVgbvDZdyQpIhO/836a5d17bb874c50e503af4d19fa41a2/fundraising-01.jpeg" class="card-img-top" alt="Funraising With wood Background" defer>

            <div class="card-body">
              <small class="card-title">Fundraising</small>
              <p class="card-text lead">Raise funds & write grants. Help archive and record artifacts and donations.</p>
            </div>
          </div>
        </div>
      </div>

      <br>

         <a class="vol lead" routerLink="/volunteer">Learn how you can help <i class="fas fa-arrow-right"></i></a>
      
     
    </div>
  </div>
<br>
</div>
  </div>
</div>
 -->
