export const environment = {
  firebase: {
    projectId: 'bayou-lacombe-museum',
    appId: '1:636359663463:web:3cc8a0b065ad3aa6aa19c2',
    databaseURL: 'https://bayou-lacombe-museum.firebaseio.com',
    storageBucket: 'bayou-lacombe-museum.appspot.com',
    locationId: 'us-central',
    apiKey: 'AIzaSyC0ioHxepYI18j3b3R7_4cPHq5mfLyprgI',
    authDomain: 'bayou-lacombe-museum.firebaseapp.com',
    messagingSenderId: '636359663463',
  },
  production: true,
  contentful: {
    space: 'f6ou4743hwzs',
    accessToken: 'zyIQOiUs4lGW5yaSyn9cpguRp9BU25mxCMUyokn2EWw'
  },
  firebaseConfig : {
    apiKey: "AIzaSyC0ioHxepYI18j3b3R7_4cPHq5mfLyprgI",
    authDomain: "bayou-lacombe-museum.firebaseapp.com",
    databaseURL: "https://bayou-lacombe-museum.firebaseio.com",
    projectId: "bayou-lacombe-museum",
    storageBucket: "bayou-lacombe-museum.appspot.com",
    messagingSenderId: "636359663463",
    appId: "1:636359663463:web:3cc8a0b065ad3aa6aa19c2"
  }
};
