<app-top></app-top> 
<span class="gap"></span>
<div class="container">
<h1 class="display-4">Exhibits</h1>
<span class="gap"></span>
<div class="exhibits">
  <mat-card *ngFor="let be of exhibits" [style.background]="'url('+be.fields.image.fields.file.url+')'">
      <h2 [innerHTML]="be.fields.title | mdToHtml"></h2>
   
    <mat-card-actions>
      <button mat-fab [routerLink]="['/exhibits', be.fields.url]" color="primary"><mat-icon class="material-icons-round">arrow_forward</mat-icon></button>
    </mat-card-actions>
  </mat-card>
</div>
  
</div>