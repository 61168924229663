<div class="card-columns">
        <div class="card" *ngFor="let blmDaily of blmDaily">
                
                <div >
                    <a [routerLink]="['/daily', blmDaily.fields.url]" class="link">
                        <img class="card-img-top" src="{{blmDaily.fields.images[0].fields.file.url}}" defer/>
                    <div class="card-body">
                      <p class="card-text"><small class="text-muted" [innerHTML]="blmDaily.fields.date | mdToHtml"></small></p>
                      <h5 class="card-title">{{ blmDaily.fields.title }} &#8212; Museum Daily</h5>
                      <p class="card-text" [innerHTML]="blmDaily.fields.shortDescription | mdToHtml"></p>
                    </div>
                  </a>
                </div>
              
          </div>
</div>
