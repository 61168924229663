import { Component, OnInit } from '@angular/core';
import { ContentfulService } from 'src/app/core/contentful.service';
import { Entry } from 'contentful';
import { NavbarService } from '../../core/navbar.service';
import { Title, Meta } from '@angular/platform-browser';
@Component({
  selector: 'app-exhibits',
  templateUrl: './exhibits.component.html',
  styleUrls: ['./exhibits.component.css']
})
export class ExhibitsComponent implements OnInit {

  public exhibits: Entry<any>[] = [];
  constructor(private title: Title, private meta: Meta,private contentfulService: ContentfulService,public nav: NavbarService) { }

  ngOnInit() {
    this.nav.show();
    this.contentfulService.getExhibits()
    .then(exhibits => this.exhibits = exhibits);
  }

}

