import { Component, OnInit } from '@angular/core';
import { ContentfulService } from '../../../core/contentful.service';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Entry } from 'contentful';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-daily-details',
  templateUrl: './daily-details.component.html',
  styleUrls: ['./daily-details.component.css']
})
export class DailyDetailsComponent implements OnInit {
  blmDaily: Entry<any>;
  constructor(public ContentfulService: ContentfulService,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.paramMap
    .pipe(switchMap((params: ParamMap) => this.ContentfulService.getDailies(params.get('slug'))))
    .subscribe( blmDaily=> this.blmDaily = blmDaily);
  }

}
