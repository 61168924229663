import { Component, OnInit } from '@angular/core';
import { ContentfulService } from '../../core/contentful.service';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Entry } from 'contentful';
import { switchMap } from 'rxjs/operators';

import { NavbarService } from '../../core/navbar.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  title='Bayou Lacombe Museum Is Open!';
  public blmDaily: Entry<any>;
  public blmEvents: Entry<any>;
  public blmExhibits: Entry<any>;
  hero: Entry<any>[] = [];

  loading = false;
  success = false;

  constructor(private contentful: ContentfulService,
    private route: ActivatedRoute,public nav: NavbarService) { }

  ngOnInit() {
    this.nav.show()
    this.contentful.getHero()
    .then(hero => {this.hero = hero});
    this.route.paramMap
    .pipe(switchMap((params: ParamMap) => this.contentful.getDailies(params.get('slug'))))
    .subscribe( blmDaily=> this.blmDaily = blmDaily);

    this.route.paramMap
    .pipe(switchMap((params: ParamMap) => this.contentful.getEvent(params.get('slug'))))
    .subscribe( blmEvents=> this.blmEvents = blmEvents);

    this.route.paramMap
    .pipe(switchMap((params: ParamMap) => this.contentful.getExhibit(params.get('slug'))))
    .subscribe( blmExhibits=> this.blmExhibits = blmExhibits);

  }
}
