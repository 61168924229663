import { Component, OnInit } from '@angular/core';
import { ContentfulService } from '../../core/contentful.service';
import { Entry } from 'contentful';
import { NavbarService } from '../../core/navbar.service';
@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {
  blmMiscellaneous: Entry<any>[] = [];
  constructor(private contentfulService: ContentfulService,public nav: NavbarService) { }

  ngOnInit() {
    this.nav.show()
    this.contentfulService.getItems()
    .then(blmMiscellaneous => this.blmMiscellaneous = blmMiscellaneous);
  }
}