import { Component, OnInit } from '@angular/core';
import { AngularFirestore} from '@angular/fire/compat/firestore';
import {FormBuilder, FormGroup,Validators} from "@angular/forms";
import { DatePipe } from '@angular/common'
import {NavbarService} from '../../core/navbar.service';

@Component({
  selector: 'app-appointments',
  templateUrl: './appointments.component.html',
  styleUrls: ['./appointments.component.css']
})
export class AppointmentsComponent implements OnInit {
  minDate: Date;
  myFilter = (d: Date | null): boolean => {
    const day = (d || new Date()).getDay();
    // Prevent  Sunday-Wendesday from being selected.
    return day !== 4 && day !== 5 && day !== 6;
  }
  myForm: FormGroup;
  
  loading = false;
  success = false;

    constructor(private fb: FormBuilder,private afs: AngularFirestore,public datepipe: DatePipe, public nav: NavbarService) { }
    
    ngOnInit() {
      
      this.minDate =new Date();
      this.nav.hide();
      this.myForm =this.fb.group({
        name:['',[
          Validators.required,
          Validators.pattern('[a-zA-Z .-]*'),
          
  
      ]],
        email:['',[
          Validators.required,
          Validators.email
        ]],
        phone:[''],
        date:['',[ Validators.required,
  
        ]],
        time:['',[ Validators.required,
  
        ]]
        
      });
      //this.myForm.valueChanges.subscribe(console.log)
    }
    get name(){
      return this.myForm.get('name');
    }
    get email(){
      return this.myForm.get('email');
    }
    get date(){
      return this.myForm.get('date')
    }
    get phone(){
      return this.myForm.get('phone')
    }
    get time(){
      return this.myForm.get('time')
    }
    async onSubmit(){
      this.loading = true;
      const formValue ={
        name:this.myForm.value.name,
        email:this.myForm.value.email,
        phone: this.myForm.value.phone,
        date:this.datepipe.transform(this.myForm.value.date, 'EEEE, MMMM d, y'),
        time:this.myForm.value.time,
        createdAt: new Date()
      } 
      try {
          await this.afs.collection('appointment').add(formValue);
         this.success = true;
      }catch(err){
      console.error(err)
      
      }
      this.loading = false;
    }
   
  }
  