import { Component, OnInit } from '@angular/core';
import { ContentfulService } from '../../../core/contentful.service';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Entry } from 'contentful';
import { switchMap } from 'rxjs/operators';
import { NavbarService } from '../../../core/navbar.service';
import { Title, Meta } from '@angular/platform-browser';
@Component({
  selector: 'app-exhibits-details',
  templateUrl: './exhibits-details.component.html',
  styleUrls: ['./exhibits-details.component.css']
})
export class ExhibitsDetailsComponent implements OnInit {
  exhibit: Entry<any>;
  constructor(private title: Title, private meta: Meta,private ContentfulService: ContentfulService, public nav: NavbarService,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.nav.show();
    this.route.paramMap
    .pipe(switchMap((params: ParamMap) => this.ContentfulService.getExhibit(params.get('slug'))))
    .subscribe( exhibit=> {
      this.exhibit = exhibit
      this.title.setTitle(exhibit.fields.title +' | Bayou lacombe Museum');
      this.meta.addTags([
        { name: 'og:url', content: exhibit.fields.url },
        { name: 'og:title', content: exhibit.fields.title },
        { name: 'og:description', content: exhibit.fields.description },
        { name: 'og:image', content: exhibit.fields.image.fields.file.url},
        { name: 'og:locale', content: 'en_US' },
        { name: 'twitter:card', content: 'summary' },
        { name: 'twitter:image', content: exhibit.fields.image.fields.file.url },
        { name: 'twitter:title', content: exhibit.fields.title },
        { name: 'twitter:description', content: exhibit.fields.description },
        { name: 'keywords', content: '' },
      ]);
    });
  }

}
