<span class="gap"></span>
<a class="d-md-none text-dark back" routerLink=""><mat-icon>arrow_back</mat-icon> Go To Home</a>
<br>
<div id="contact" class="container text-center" >
    <div class="row">
  <div class="container">
    <div class="col-12">
      <span class="gap d-md-none"></span>    
        <h1 class="display-4">
           Plan a Visit

        </h1>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <span class="gap"></span>
        <img src="https://images.ctfassets.net/f6ou4743hwzs/5nF9Uk0nXxFOFAt23EbZM2/09cb232e340b224499c075d912461494/undraw_art_lover_yjfr.svg" alt="Person Looking at Art" defer>
        <span class="gap"></span>
      </div>
    <div class="col-lg-6">
      <form [formGroup]="myForm" [hidden]="success" (ngSubmit)="onSubmit()">
        <div class="form-group"[ngClass]="{
          'has-success': email.valid && (email.dirty || email.touched),
          'has-danger': email.invalid && (email.dirty || email.touched)
        }">
    <div class="col-12">
      <p class="lead">To set up a tour  Please Fill Out The Form To Set Up An Appointment Here or Text/Call</p>
      
      <a class="text-dark" href="tel:(985) 664-0256">(985) 664-0256<span class="gap"></span></a>
      
      
      <!--For testing-->
       <!--Value: {{myForm.value | json}}-->
      <!--Name-->
       <mat-form-field appearance="fill">
         <mat-label>Name</mat-label>
         <input matInput formControlName="name" autocomplete="name">
        </mat-form-field>
    </div>
     <!--Email-->
    <div class="col-12 email">

        <mat-form-field appearance="fill">
          <mat-label>Email</mat-label>
          <input matInput formControlName="email" autocomplete="email">
            
        </mat-form-field>
      <!--Phone-->
      <mat-form-field appearance="fill">
          <mat-label>Phone</mat-label>
          <input matInput formControlName="phone" autocomplete="tel">
         </mat-form-field>
        </div>
        <!--Text Area-->
        <div class="col-12 ">
          <mat-form-field appearance="fill">
          <mat-label>Click Icon to Choose a date</mat-label>
          <input matInput [min]="minDate" [matDatepickerFilter]="myFilter" [matDatepicker]="picker" formControlName="date" autocomplete="off">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker touchUi #picker></mat-datepicker>
        </mat-form-field>
        
     </div>
     <div class="col-12">
    <mat-form-field appearance="fill">
      <mat-label>Select A Time</mat-label>
           <select matNativeControl formControlName="time" required>
              <option value="10AM">10 A.M</option>
              <option value="11AM">11 A.M</option>
              <option value="12PM">12 P.M</option>
              <option value="1PM">1 P.M</option>
              <option value="2PM">2 P.M</option>
              <option value="3PM">3 P.M</option>
              
          </select>
  </mat-form-field>
  
</div>
     <div class="col-12">
        
        <button mat-flat-button [disabled]="myForm.invalid"  color="accent" type="submit">Set Appointment</button>
     </div>
      </div>
      </form>
    </div>
    
    </div>
        <div *ngIf="success" class="success text-center">
          <h1>Appointment Set</h1>
          <p class="thank-you">Your appointment has been set. Someone will contact you to comfirm.</p> 
      </div>
      </div>
  </div> 
  </div>
     
  
  