<br>
<br>
<br>
<div class="container">
    <div class="row">
        <div class="col-12">
            <div class="login-box text-center">
                    <h1 class="display-4">Login</h1>
                    <hr class="light">
                <form [formGroup]="loginForm"  (ngSubmit)="signin()">
                <!--Email-->
                <mat-form-field>
                    <input matInput placeholder="Email" type="email" autocomplete="email" formControlName="email" required/>
                    <div *ngIf="formErrors.email">
                        {{ formErrors.email }}
                      </div>
                </mat-form-field>
                <!--Password-->
                <mat-form-field>
                    <input matInput placeholder="Password" type="password" autocomplete="current-password" formControlName="password" required/>
                    <div *ngIf="formErrors.password">
                        {{ formErrors.password }}
                      </div>
                </mat-form-field>
                <button type="submit" [disabled]="!loginForm.valid">Sign In</button>
                </form>
            </div>
        </div>
    </div>
</div>
