<span class="gap"></span>

<div class="container">
  <div class="events">
  <mat-card *ngFor="let event of events">
    <img mat-card-image [src]="event.fields.images[0].fields.file.url" [alt]="event.fields.imageName" de/>
    <mat-card-title [innerHTML]="event.fields.title | mdToHtml"></mat-card-title>
    <mat-card-content>
      <p [innerHTML]="event.fields.shortDescription | mdToHtml"></p>
    </mat-card-content>
    <mat-card-actions align="end">
      <a mat-button color="primary" [routerLink]="['/events', event.fields.url]">Learn More</a> 
    </mat-card-actions>
  </mat-card>
</div>

<div class="no-events" *ngIf="events.length ==0">
  <h1>No events at this time</h1>
</div>
</div>

