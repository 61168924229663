<br>
<br>
  <div class="container">
    <div class="row text-center">
  <div class="container text-center">
    <div class="col-12 text-center">
              
        <h1 class="display-4">Volunteer Signup</h1>
        <hr>
    </div>
    <div class="form-group "[ngClass]="{
      'has-success': email.valid && (email.dirty || email.touched),
      'has-danger': email.invalid && (email.dirty || email.touched)
    }">
      
        <form class="col-6" [hidden]="success" [formGroup]="volunteerForm" (ngSubmit)="onSubmit()" >
      <div class="col-12">
        <!--For testing-->
         <!--Value: {{volunteerForm.value | json}}-->
        <!--Name-->
         <mat-form-field appearance="fill">
           <mat-label>Name</mat-label>
           <input matInput  formControlName="name" autocomplete="name">
            <mat-error *ngIf="name.errors && (name.dirty || name.touched)">
                Name Required
            </mat-error>
          </mat-form-field>
      </div>
       <!--Email-->
      <div class="col-12 email">
          <mat-form-field appearance="fill">
            <mat-label>Email</mat-label>
            <input matInput formControlName="email" autocomplete="email">
              <mat-error *ngIf="email.errors && (email.dirty || email.touched)">
                  Invalid Email
              </mat-error>
          </mat-form-field>

          </div>
          <!--Volunteer-->
          <h4 >I would like to volunteer to help with</h4>
          <br>
          <mat-radio-group aria-label="Select an option" formControlName="volunteer">
            <mat-radio-button value="Daily">Daily Operations</mat-radio-button>&nbsp;
            <mat-radio-button value="Events">Special Events</mat-radio-button>&nbsp;
            <mat-radio-button value="Fundraising">Fundraising</mat-radio-button>
          </mat-radio-group>
          <br>
          <button mat-flat-button [disabled]="volunteerForm.invalid"  type="submit">Sign Me Up</button>
     
        
        </form>
        <div class="jumbotron text-center" *ngIf="success">
    
          <h2>Thank You, Your Voluneeter Form has been Sent</h2>
           </div>
      </div>
  
  
  </div>

  

   
      