<br>
<br>
<br>
<div *ngIf="blmDaily">
    <div class="col-12">
       <h1 class="display-4 text-center"[innerHTML]="blmDaily.fields.title | mdToHtml"></h1> 
  
      
      <div [innerHTML]="blmDaily.fields.longDescription | mdToHtml"></div>
  
    </div>
  </div>
