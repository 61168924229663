<app-top></app-top> 
<span class="gap"></span>
<div *ngIf="exhibit">
<div class="container">
<button mat-fab extended="true" routerLink="/exhibits"><mat-icon class="material-icons-round">arrow_back</mat-icon>Back</button>
<span class="gap"></span>
      <h1 class="display-4" [innerHTML]="exhibit.fields.title | mdToHtml"></h1>
  <div class="exhibit-page">
    <div>
      <img [src]="exhibit.fields.image.fields.file.url" class="card-img" [alt]="exhibit.fields.title" defer > 
    </div>
    
    <div>
        <span class="badge explore">{{exhibit.fields.temporaryExhibit?'Temporary Exhibit':'Ongoing Exhibit'}}</span>

        <div *ngIf="exhibit.fields.temporaryExhibit">
          <span class="gap"></span>  
          <mat-chip-set>
          <mat-chip><mat-icon class="material-icons-round">schedule</mat-icon>&nbsp;{{exhibit.fields.startDate|date}} - {{exhibit.fields.endDate|date}}</mat-chip>
        </mat-chip-set>
        </div>
                                 
        <p [innerHTML]="exhibit.fields.longDescription | mdToHtml"></p>
        
    </div>
</div>  
</div>
</div>


 


                