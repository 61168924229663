import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../core/auth.service';
import { ReactiveFormsModule, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;


  constructor(public fb: FormBuilder,   private router: Router,public auth: AuthService ) { }//public auth: AuthService

  ngOnInit() {

   // Validators
    this.loginForm = this.fb.group({
      'email': ['', [
        Validators.required,
        ]
      ],
      'password': ['', [
        Validators.required
        ]
      ],
    });
    this.loginForm.valueChanges.subscribe(data => this.onValueChanged(data));
    this.onValueChanged(); // reset validation messages
  }

  // Using getters will make your code look pretty
  get email() { return this.loginForm.get('email') }
  get password() { return this.loginForm.get('password') }




  // Step 1
  signin() {

  return this.auth.emailLogin(this.email.value, this.password.value);
  

  }

   // Updates validation state on form changes.
   onValueChanged(data?: any) {
    if (!this.loginForm) { return; }
    const form = this.loginForm;
    for (const field in this.formErrors) {
      // clear previous error message (if any)
      this.formErrors[field] = '';
      const control = form.get(field);
      if (control && control.dirty && !control.valid) {
        const messages = this.validationMessages[field];
        for (const key in control.errors) {
          this.formErrors[field] += messages[key] + ' ';
        }
      }
    }
  }

 formErrors = {
    'email': '',
    'password': ''
  };

  validationMessages = {
    'email': {
      'required':      'Email is required.',
      'email':         'Email must be a valid email'
    },
    'password': {
      'required':      'Password is required.',
    }
  };


}