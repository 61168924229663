import { Component, OnInit } from '@angular/core';
import { AngularFirestore} from '@angular/fire/compat/firestore';

import {FormBuilder, FormGroup,Validators} from "@angular/forms";
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import * as firebase from 'firebase/app';
import { NavbarService } from '../../core/navbar.service';

@Component({
  selector: 'app-volunteer',
  templateUrl: './volunteer.component.html',
  styleUrls: ['./volunteer.component.css']
})
export class VolunteerComponent implements OnInit {

  volunteerForm: FormGroup;
  
  loading = false;
  success = false;

    constructor(private fb: FormBuilder,private afs: AngularFirestore, private fun: AngularFireFunctions,public nav: NavbarService) { }
  
    ngOnInit() {
      this.nav.hide()
      this.volunteerForm =this.fb.group({
        name:['',[
          Validators.required,
          Validators.pattern('[a-zA-Z .-]*'),
          
  
      ]],
        email:['',[
          Validators.required,
          Validators.email
        ]],

        volunteer:['',[
  
        ]]
        
      });
      //this.volunteerForm.valueChanges.subscribe(console.log)
    }
    get name(){
      return this.volunteerForm.get('name');
    }
    get email(){
      return this.volunteerForm.get('email');
    }
    get volunteer(){
      return this.volunteerForm.get('volunteer')
    }
 
    async onSubmit(){
      this.loading = true;
      const formValue ={
        name:this.volunteerForm.value.name,
        email:this.volunteerForm.value.email,
        volunteer:this.volunteerForm.value.volunteer,
        createdAt: new Date()
      } 
      try {
          await this.afs.collection('volunteer').add(formValue);
         this.success = true;
         const callable = this.fun.httpsCallable('volunteer');
         callable({ email:this.volunteerForm.value.email, name:this.volunteerForm.value.name}).subscribe();
          this.loading = false;
      }catch(err){
      console.error(err)
      
      }

    }
   
  }
