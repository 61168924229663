import { Component, OnInit } from '@angular/core';

import { AngularFirestore} from '@angular/fire/compat/firestore';

import {FormBuilder, FormGroup,Validators} from "@angular/forms";
import { NavbarService } from '../../core/navbar.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  myForm: FormGroup;
  
  loading = false;
  success = false;

    constructor(private fb: FormBuilder,private afs: AngularFirestore,public nav: NavbarService) { }
  
    ngOnInit() {
      this.nav.hide()
      this.myForm =this.fb.group({
        name:['',[
          Validators.required,
          Validators.pattern('[a-zA-Z .-]*'),
          
  
      ]],
        email:['',[
          Validators.required,
          Validators.email
        ]],

        message:['',[
  
        ]]
        
      });
      //this.myForm.valueChanges.subscribe(console.log)
    }
    get name(){
      return this.myForm.get('name');
    }
    get email(){
      return this.myForm.get('email');
    }
    get message(){
      return this.myForm.get('message')
    }
 
    async onSubmit(){
      this.loading = true;
      const formValue ={
        name:this.myForm.value.name,
        email:this.myForm.value.email,
        message:this.myForm.value.message,
        createdAt: new Date()
      } 
      try {
          await this.afs.collection('contact').add(formValue);
         this.success = true;
      }catch(err){
      console.error(err)
      
      }
      this.loading = false;
    }
   
  }
  