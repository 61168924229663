import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { DatePipe } from '@angular/common'
//material design
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatRadioModule} from '@angular/material/radio';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatIconModule} from '@angular/material/icon';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatButtonModule} from '@angular/material/button';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatNativeDateModule} from '@angular/material/core';
import {MatCardModule} from '@angular/material/card';
import {MatListModule} from '@angular/material/list';
import {MatChipsModule} from '@angular/material/chips';
//Forms
import { FormsModule }   from '@angular/forms';
import {ReactiveFormsModule} from '@angular/forms';

//firebase
import { AngularFirestoreModule, AngularFirestore, } from '@angular/fire/compat/firestore';
import {AngularFireFunctionsModule } from '@angular/fire/compat/functions';
import {AngularFireModule} from '@angular/fire/compat';
import { AngularFireAuthModule} from '@angular/fire/compat/auth';
 
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { RouterModule,Routes } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MdToHtmlPipe } from './core/md-to-html.pipe';
import { HomeComponent } from './navigation/home/home.component';
import { AboutComponent } from './navigation/about/about.component';
import { ContactComponent } from './navigation/contact/contact.component';
import { EventsComponent } from './navigation/events/events.component';
import { ExhibitsComponent } from './navigation/exhibits/exhibits.component';
import { EventDetailsComponent } from './navigation/events/event-details/event-details.component';
import { ExhibitsDetailsComponent } from './navigation/exhibits/exhibits-details/exhibits-details.component';
import { MembershipsComponent } from './navigation/memberships/memberships.component';
import { DailyComponent } from './navigation/daily/daily.component';
import { DailyDetailsComponent } from './navigation/daily/daily-details/daily-details.component';
import { ContentfulService } from './core/contentful.service';
import { VolunteerComponent } from './navigation/volunteer/volunteer.component';
import { LoginComponent } from './entry/login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AuthGuard } from './core/auth.guard';
import { MembershipDetailsComponent } from './navigation/memberships/membership-details/membership-details.component';
import { ElementsComponent } from './payment/elements/elements.component';
import { PaymentRequestComponent } from './payment/payment-request/payment-request.component';
import { AppointmentsComponent } from './navigation/appointments/appointments.component';
import { BottomComponent } from './nav/bottom/bottom.component';
import { TopComponent } from './nav/top/top.component';
import {NavbarService} from '../app/core/navbar.service';
import { initializeApp,provideFirebaseApp } from '@angular/fire/app';
import { provideFirestore,getFirestore } from '@angular/fire/firestore'

const pages: Routes =[
  /*
  {path: '',component:},
  {path: '',component:},
  */
  {path: '',component: HomeComponent},
  {path: 'about',component: AboutComponent},
  {path: 'contact',component: ContactComponent },
  
  //Memberships
  /**
   *   {path: 'memberships',component: MembershipsComponent},
  {path: 'memberships/become-a-member',component: MembershipDetailsComponent},
   */


  //Exhibits
  {path: 'exhibits',component: ExhibitsComponent},
  {path: 'exhibits/:slug',component: ExhibitsDetailsComponent },
  //Events
  {path: 'events',component: EventsComponent },
  {path: 'events/:slug',component: EventDetailsComponent },
  //Daily
  {path: 'daily',component: DailyComponent },
  {path: 'daily/:slug', component :DailyDetailsComponent},
  //Volunteer
  {path: 'volunteer',component: VolunteerComponent },
  //Login
  {path: 'login',component: LoginComponent},
  //Dashboard
  {path: 'dashboard',component:DashboardComponent,canActivate: [AuthGuard]},
  {path: 'appointment',component: AppointmentsComponent},
];

@NgModule({
  declarations: [
    AppComponent,
    MdToHtmlPipe,
    HomeComponent,
    AboutComponent,
    ContactComponent,
    EventsComponent,
    ExhibitsComponent,
    EventDetailsComponent,
    ExhibitsDetailsComponent,
    MembershipsComponent,
    DailyComponent,
    DailyDetailsComponent,
    VolunteerComponent,
    LoginComponent,
    DashboardComponent,
    MembershipDetailsComponent,
    ElementsComponent,
    PaymentRequestComponent,
    AppointmentsComponent,
    BottomComponent,
    TopComponent,
 
   
  ],
  imports: [
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    MatButtonModule,
    MatCheckboxModule,
    MatRadioModule,
    MatDatepickerModule,
    MatGridListModule,
    MatListModule,
    MatIconModule,
    MatToolbarModule,
    MatNativeDateModule,
    MatChipsModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    RouterModule.forRoot(pages, { scrollOffset: [0, 0], scrollPositionRestoration: 'top'}),
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireModule,
    AngularFirestoreModule,
    AngularFireFunctionsModule,
    AngularFireAuthModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideFirestore(() => getFirestore()),
  ],
  exports:[RouterModule],
  providers:[ ContentfulService,NavbarService,DatePipe],
  bootstrap: [AppComponent],
  
})
export class AppModule {
  
 }
